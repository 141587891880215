
@import 'styles/colours';
@import 'styles/fonts';
@import 'styles/layout';

body {
  background-color: $background;
  text-align: center;
  color: $foreground;
}

div {
  background-color: $background;
}

p {
  text-align: center;
}

a {
  color: $link;
}

header {
  ::before {
    height: 10vmin;
  }
  height: 40vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: $big-text;
}

.body {
  height: 50vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: $small-text;
}

body {
  margin: 0;
  font-family: $default-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: $code-font-family;
}
